import React, { useState, useEffect } from "react";
import DataService from "../services/api/data_services";
import Layout from "../components/layouts/layout";

// import ogimage from "../assets/Images/ogImages/og-digiryte.png";
import { Card, Grid } from "@material-ui/core";

import CtaCardSection from "../components/CTASection/ctaCard2";
import FormSection from "../components/FormSection";
import WhyDeveloper from "../components/WhyDevelopers";
import HeroSection from "../components/HeroSection";
import TrustedPartner from "../components/trustedPartner";
import CTAIMAGE1 from "../assets/Images/Clutch/Cta/phone-img.png";
import CTAIMAGE2 from "../assets/Images/Clutch/Cta/phone-contact.png";
import CTAIMAGE3 from "../assets/Images/Clutch/Cta/telephone-illustration.png";
import CTAIMAGE4 from "../assets/Images/Clutch/Cta/contact-us-4.png";

import LPFooter from "../components/LPFooter";
import BlurCardList from "../templates/BlurCardList";
import MiniCardBgImage1 from "../assets/Images/Homepage/mobile_application_development.png";
import MiniCardBgImage2 from "../assets/Images/Homepage/web_application_development.png";
import MiniCardBgImage3 from "../assets/Images/Homepage/full_product_development.png";
import MiniCardBgImage4 from "../assets/Images/Homepage/MVP.png";
import MiniCardBgImage5 from "../assets/Images/Homepage/Product_designing_and_Prototyping.png";
import MiniCardBgImage6 from "../assets/Images/Homepage/product_scaling.png";
import MiniCardBgImage7 from "../assets/Images/Homepage/code_ux_audit.png";
import MiniCardBgImage8 from "../assets/Images/Homepage/digital_transformation.png";
import MiniCardBgImage9 from "../assets/Images/Homepage/machine_learning.png";
import clientMovieSliderImage from "../assets/Images/HowWeDoIt/sdfljdsf_2x.png";

import PlainCard from "../components/SixPlainCard";
import hoverCardImg1 from "../assets/Images/HowWeDoIt/Synchronised_Development_Operations.svg";
import hoverCardImg2 from "../assets/Images/HowWeDoIt/Completely_Automated_SSDLC.svg";
import hoverCardImg3 from "../assets/Images/HowWeDoIt/Cost_cutting_on_Maintenance_Updates.svg";
import hoverCardImg4 from "../assets/Images/HowWeDoIt/Reduced_Implementation_Time.svg";
import hoverCardImg5 from "../assets/Images/HowWeDoIt/Centralised_repository.svg";
import hoverCardImg6 from "../assets/Images/HowWeDoIt//Proactive_Security_measures.svg";
import Infoview from "../templates/InfoView";
import MobileSmoothSlider from "../components/MobileSmoothSlider";
import TechnologySection from "../components/TechnologySection";

import crossPlatform from "../assets/Images/MobileApp/Group_6247_2x.png";
import nativeApp from "../assets/Images/MobileApp/Group_6261_2x.png";
import hybridApp from "../assets/Images/MobileApp/Group_6272_2x.png";
import machineSlide from "../assets/Images/machineLearning/machine_learning_LS@2x.png";
import frontEnd from "../assets/Images/MobileApp/Group_6252_2x.png";
import backEnd from "../assets/Images/MobileApp/Group_6256_2x.png";

//Web Development
import Angular from "../assets/Images/MobileApp/Weblanguages_wespeak/Angular.png";
import EmberJs from "../assets/Images/MobileApp/Weblanguages_wespeak/EmberJS.png";
import PhpLogo from "../assets/Images/MobileApp/Weblanguages_wespeak/Php.png";
import PythonLogo from "../assets/Images/MobileApp/Weblanguages_wespeak/Python.png";
import RailsLogo from "../assets/Images/MobileApp/Weblanguages_wespeak/Rails.png";
import DotNetLogo from "../assets/Images/MobileApp/Weblanguages_wespeak/Dot_Net.png";

//Mobile Development
import ReactNativeLogo from "../assets/Images/MobileApp/mobilelang_wespeak/react-native.png";
import ReactLogo from "../assets/Images/MobileApp/mobilelang_wespeak/react-js-logo.png";
import Vue from "../assets/Images/MobileApp/mobilelang_wespeak/Vue.js_Logo.png";
import Flutter from "../assets/Images/MobileApp/mobilelang_wespeak/flutter-logo.png";
import Kotlin from "../assets/Images/MobileApp/mobilelang_wespeak/Kotlin-logo.png";
import Android from "../assets/Images/MobileApp/mobilelang_wespeak/Android_new_logo.png";
import Swift from "../assets/Images/MobileApp/mobilelang_wespeak/Swift_logo.png";
import XamarinLogo from "../assets/Images/MobileApp/mobilelang_wespeak/xamarin-logo.png";
import iOS from "../assets/Images/MobileApp/mobilelang_wespeak/iOS-logo.png";
import IonicLogo from "../assets/Images/MobileApp/mobilelang_wespeak/Ionic_Logo.png";
import PhoneGap from "../assets/Images/MobileApp/mobilelang_wespeak/PhoneGap-Stacked-Black-logo.png";
import VisualStudio from "../assets/Images/MobileApp/mobilelang_wespeak/Microsoft Visual Studio.png";

// Ml Language
import TensorFlow from "../assets/Images/TechnologyUsed/TensorFlow.png";
import Keras from "../assets/Images/TechnologyUsed/Keras.png";
import OpenCV from "../assets/Images/TechnologyUsed/OpenCv.png";
import ScikitLearn from "../assets/Images/TechnologyUsed/Scikit-learn.png";
import Pandas from "../assets/Images/TechnologyUsed/Pandas.png";
import Theano from "../assets/Images/TechnologyUsed/Theano.png";
import PyTorch from "../assets/Images/TechnologyUsed/PyTorch.png";
import Miniview from "../templates/MiniView";

import ReadMoreWithLeadForm from "../components/ReadMoreWithLeadForm";
import RevealAnimation from "../components/RevealAnimation";
// import { useContactModal } from "../components/Modal/contactModalContext";

const ClutchPage = () => {
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(3);

  const BlurCardListData = {
    cardsData: [
      {
        image: MiniCardBgImage1,
        title: "Mobile Application Development",
        subTitle:
          "Take business to your customers by building advanced native and hybrid mobile apps for both Android and iOS Platforms.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "",
        route: "",
        button: "no",
      },
      {
        image: MiniCardBgImage2,
        title: "Web Application Development",
        subTitle:
          "Accelerate different functions of your business by creating custom web applications using the latest in digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "",
        route: "",
        button: "no",
      },
      {
        image: MiniCardBgImage4,
        title: "MVP Development",
        subTitle:
          "Build a basic model to demonstrate the core functionality of your product, test it with early adopters and fulfil the primary goal of your business.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "",
        route: "",
        button: "no",
      },
      {
        image: MiniCardBgImage3,
        title: "Full Product Development",
        subTitle:
          "The process of full product development encompasses all steps needed to take your product from concept to market place.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "",
        route: "",
        button: "no",
      },
      {
        image: MiniCardBgImage5,
        title: "Product Designing and Prototyping",
        subTitle:
          "Create a prototype of your product to visualise your idea and generate feedback without incurring the building costs of a fully functioning system.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "",
        route: "",
        button: "no",
      },
      {
        image: MiniCardBgImage6,
        title: "Product Scaling",
        subTitle:
          "Found your product-market fit? Great! Let us help you with a profitable scaling process that provides both sustainability and stability for your product.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "",
        route: "",
        button: "no",
      },
      {
        image: MiniCardBgImage7,
        title: "Code & UX Audit",
        subTitle:
          "With a regular audit, we can help you fix your product, improve its performance, gain new users, and boost your brand reputation in the marketplace.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "",
        route: "",
        button: "no",
      },
      {
        image: MiniCardBgImage8,
        title: "Digital Transformation",
        subTitle:
          "Update your traditional business processes and customer experience to meet the ever-changing market requirements with the help of next-generation digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "",
        route: "",
        button: "no",
      },
      {
        image: MiniCardBgImage9,
        title: "Machine Learning",
        subTitle:
          "Through machine learning, we can help you make informed decisions to improve business scalability and operational efficiency.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "",
        route: "",
        button: "no",
      },
    ],
  };
  const InfoviewData1 = {
    InfoviewTitle: (
      <div className="ttc">
        <span className="red-line3letter red-3letter-mbl">Our</span> App
        Development Includes
      </div>
    ),
    tag: "h2",
    width: "100px",
    InfoviewContent: <></>,
  };

  const PlainCardData = [
    {
      HoverCardWithBtntitle: "Synchronised Development & Operations",
      HoverCardWithBtnsubtitle: "(Improves Agility)",
      HoverCardWithBtnIcon: hoverCardImg1,
      HoverCardWithBtnIconAlt: "Synchronised Development & Operations",
      HoverCardIconClass: "plain-card-button-main-img",
    },
    {
      HoverCardWithBtntitle: "Completely Automated SSDLC",
      HoverCardWithBtnIcon: hoverCardImg2,
      HoverCardWithBtnIconAlt: "Completely Automated SSDLC",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: "(Secure Software Development Life-cyle)",
    },
    {
      HoverCardWithBtntitle: "Cost cutting on Maintenance & Updates",
      HoverCardWithBtnIcon: hoverCardImg3,
      HoverCardWithBtnIconAlt: "Cost cutting on Maintenance & Updates",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: "(Cost cutting without Compromising Quality)",
    },
    {
      HoverCardWithBtntitle: "Reduced Implementation Time",
      HoverCardWithBtnIcon: hoverCardImg4,
      HoverCardWithBtnIconAlt: "Reduced Implementation Time",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: "(Months to Minutes)",
    },
    {
      HoverCardWithBtntitle: "Centralised repository",
      HoverCardWithBtnIcon: hoverCardImg5,
      HoverCardWithBtnIconAlt: "Centralised repository",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle:
        "(Version control system for Improved Collobration)",
    },
    {
      HoverCardWithBtntitle: "Proactive Security measures",
      HoverCardWithBtnIcon: hoverCardImg6,
      HoverCardWithBtnIconAlt: "Proactive Security measures",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: "(From App to Infrastructure)",
    },
  ];

  //Movie Slider
  const movieSlider = {
    imageUrl: clientMovieSliderImage,
  };

  // Mobile movie slider
  const frontEndMovieSlider = {
    imageUrl: frontEnd,
  };
  const backEndMovieSlider = {
    imageUrl: backEnd,
  };
  const mobCrossMovieSlider = {
    imageUrl: crossPlatform,
  };
  const mobNativeMovieSlider = {
    imageUrl: nativeApp,
  };
  const mobHybridEndMovieSlider = {
    imageUrl: hybridApp,
  };
  const mlAiMovieSlider = {
    imageUrl: machineSlide,
  };

  useEffect(() => {
    DataService.getData(
      `/admin/api/who-we-work?limit=${limit}&page=${page}&type=Client%20Story&component=mini_view`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);

  return (
    <Layout
      seo={{
        title: "Clutch | Digiryte",
        ogtitle: "Clutch | Digiryte",
        description:
          "Explore Digiryte's Clutch profile to see our track record of accelerating digital capabilities for startups, scaleups, and enterprises across various industries.",
        // ogimage: ogImage,
        // ogurl: `${BASE_SITE_URL}/who-we-work-with`,
        keywords:
          "Clutch, Digiryte reviews, digital capabilities, startups, scaleups, enterprises",
      }}
      isLandingPage={true}
      pageUrl={"-clutch"}
    >
      <HeroSection />
      <div>
        <TrustedPartner
          showVideo={true}
          title={
            <>
              Trusted partner for startups and <br />
              Fortune 500 companies
            </>
          }
          showAllBtn={true}
          imgContainerClassName="custom-gap"
        />
      </div>
      <div>
        <CtaCardSection
          title={
            <>
              Are you struggling to find a trusted <br />
              custom software development <br />
              partner? <span className="animationTitle">Contact Us </span>
            </>
          }
          titleClassName="customTitle"
          imageUrl={CTAIMAGE1}
          imgClassName="custom-cta-img"
        />
      </div>
      <div>
        <WhyDeveloper
          showMoreBtn={true}
          title={
            <>
              Why We Select Only The Top 3% of <br />
              Developers For You
            </>
          }
        />
      </div>
      <div>
        <div className="body-container mt-40 mb-10">
          <RevealAnimation
            component={"h2"}
            className="title-header title-underline"
          >
            Testimonials from delighted clients
          </RevealAnimation>
          <div className="mt-70">
            <div
              className="clutch-widget"
              data-url="https://widget.clutch.co"
              data-widget-type="8"
              data-height="300"
              data-nofollow="true"
              data-expandifr="true"
              data-scale="100"
              data-reviews="283278,281623,266050,265807,209289,157565"
              data-clutchcompany-id="86428"
            ></div>
          </div>
        </div>
      </div>
      <div className="common-o-bg mt-70">
        <CtaCardSection
          title={
            <>
              <span className="animationTitle">Contact Us </span>
              to build your <br />
              success story
            </>
          }
          // titleClassName="customTitle"
          imageUrl={CTAIMAGE2}
          imgClassName="custom-cta-img custom-cta-darken"
        />
      </div>
      <div className="mt-70">
        <div className="body-container">
          <RevealAnimation
            component={"h2"}
            className="title-header mt-15 title-underline"
          >
            From Concept to Completion: <br /> Our Full-Stack Expertise
          </RevealAnimation>
        </div>
        <div className="mt-50">
          <BlurCardList
            componentId="1"
            BlurCardListData={BlurCardListData}
            isLandingPage={true}
          />
        </div>
      </div>
      <div className="common-o-bg mt-70">
        <CtaCardSection
          title={
            <>
              <span className="animationTitle">Contact Us </span>
              to <br /> estimate your product
            </>
          }
          // titleClassName="customTitle"
          imageUrl={CTAIMAGE3}
          imgClassName="custom-cta-darken custom-cta-img3"
        />
      </div>
      <div className="mt-70">
        <Infoview InfoviewData={InfoviewData1} />
      </div>
      <div className="body-container">
        <Grid
          className="mt-50  hide-in-mobile"
          container
          direction="row"
          spacing={3}
        >
          {PlainCardData.map((item, index) => (
            <Grid className="appsgrid" key={index} item md={4}>
              <Card className="appsgridshadow">
                <PlainCard
                  HoverCardWithBtnCustomClass={item.HoverCardWithBtnCustomClass}
                  HoverCardWithBtntitle={item.HoverCardWithBtntitle}
                  HoverCardWithBtnIcon={item.HoverCardWithBtnIcon}
                  HoverCardWithBtnIconAlt={item.HoverCardWithBtnIconAlt}
                  HoverCardIconClass={item.HoverCardIconClass}
                  HoverCardWithBtnsubtitle={item.HoverCardWithBtnsubtitle}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      <Grid className="show-only-in-mobile">
        <MobileSmoothSlider reduceheight="no" movieSlider={movieSlider} />
      </Grid>

      <div className="common-o-bg mt-70">
        <CtaCardSection
          title={
            <>
              For free code <br /> assessment.{" "}
              <span className="animationTitle">Get in touch </span>
            </>
          }
          // titleClassName="customTitle"
          imageUrl={CTAIMAGE4}
          imgClassName="custom-cta-darken"
        />
      </div>

      <TechnologySection
        mainTitle={
          <>
            <span className="red-line3letter">We</span>b Languages We Speak
          </>
        }
        mainTitleClass="mt-70"
        sectionTitle={"Front-End Development"}
        mobileSlider={frontEndMovieSlider}
        slideId={"slide1"}
        listData={[
          { name: "Angular", imgUrl: Angular },
          { name: "React.js", imgUrl: ReactLogo },
          { name: "Vue.js", imgUrl: Vue },
          { name: "Ember.js", imgUrl: EmberJs },
        ]}
        showDivider={true}
      />
      <TechnologySection
        hideMainTitle={true}
        mainTitleClass=""
        sectionTitle={"Back-End Development"}
        mobileSlider={backEndMovieSlider}
        slideId={"slide2"}
        listData={[
          { name: "Ruby on Rails", imgUrl: RailsLogo },
          { name: "Python", imgUrl: PythonLogo },
          { name: "PHP", imgUrl: PhpLogo },
          { name: ".NET core", imgUrl: DotNetLogo },
        ]}
        showDivider={true}
      />
      <TechnologySection
        mainTitle={
          <>
            <span className="red-line3letter">Mo</span>bile Languages We Speak
          </>
        }
        mainTitleClass="mt-50"
        sectionTitle={"Cross platform Development"}
        mobileSlider={mobCrossMovieSlider}
        slideId={"slide3"}
        listData={[
          { name: "Flutter", imgUrl: Flutter },
          { name: "Xamarin", imgUrl: XamarinLogo },
          { name: "React Native", imgUrl: ReactNativeLogo },
        ]}
        showDivider={true}
      />
      <TechnologySection
        hideMainTitle={true}
        mainTitleClass=""
        sectionTitle={"Native app Development"}
        mobileSlider={mobNativeMovieSlider}
        slideId={"slide4"}
        listData={[
          { name: "Java", imgUrl: Android },
          { name: "IOS", imgUrl: iOS },
          { name: "Kotlin", imgUrl: Kotlin },
          { name: "Swift", imgUrl: Swift },
        ]}
        showDivider={true}
      />
      <TechnologySection
        hideMainTitle={true}
        mainTitleClass=""
        sectionTitle={"Hybrid Development"}
        mobileSlider={mobHybridEndMovieSlider}
        slideId={"slide5"}
        listData={[
          { name: "Ionic", imgUrl: IonicLogo },
          { name: "Adobe PhoneGap", imgUrl: PhoneGap },
          { name: "Microsoft Visual Studio", imgUrl: VisualStudio },
        ]}
        showDivider={true}
      />

      <TechnologySection
        mainTitle={
          <>
            <span className="red-line3letter">ML</span> /AI Technologies We Use
          </>
        }
        mainTitleClass="mt-50"
        sectionTitle={""}
        mobileSlider={mlAiMovieSlider}
        slideId={"slide6"}
        listData={[
          { name: "Python", imgUrl: PythonLogo },
          { name: "TensorFlow", imgUrl: TensorFlow },
          { name: "Keras", imgUrl: Keras },
          { name: "OpenCV", imgUrl: OpenCV },
          { name: "Scikit-learn", imgUrl: ScikitLearn },
          { name: "Pandas", imgUrl: Pandas },
          { name: "Theano", imgUrl: Theano },
          { name: "PyTorch", imgUrl: PyTorch },
        ]}
        showDivider={false}
        showMoreClass={"-b-20"}
      />

      {/* {clientStoryData && clientStoryData.length > 0 && ( */}
      <div className="mt-70 common-bg-light">
        <div className="body-container">
          <RevealAnimation component={"h2"} className="title-header ttc">
            <span className="red-line3letter">Cli</span>ent Stories
          </RevealAnimation>
        </div>
        <div className="mt-50">
          <Miniview
            miniviewData={clientStoryData}
            total={clientStoryData.length}
            // quotedMiniCardData={quotedMiniCardData}
            isLandingPage={true}
          />
        </div>
        <ReadMoreWithLeadForm
          has_more={clientStoryRawData.has_more}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
        />
      </div>
      {/* )} */}

      <div className="mt-70">
        <div className="body-container booking-widget-container">
          <iframe
            width="100%"
            height="750px"
            src="https://bookings.digiryte.com/portal-embed#/harry"
            frameBorder="0"
            allowFullScreen=""
          />
        </div>
      </div>

      <div className="common-bg-light mt-70">
        <FormSection
          subTitle="From Ideation to Scaling"
          title="Build or Enhance world class tech product"
        />
      </div>
      <LPFooter />
    </Layout>
  );
};

export default ClutchPage;
